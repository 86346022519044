@import './Constants.css';

.App {
  z-index: 1;
  position: fixed;
  text-align: center;
  width: 100%;
  margin-left: -8px;
  margin-top: -8px;
}

html {
  font-family: "Century Gothic"; 
}

.App-header {
  /*background-color: var(--cinnabar);*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
